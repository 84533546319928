import React, { useEffect, useState } from "react";
import { fetchUserDataByUid, getAllIBuiltThis } from "api/db";

import { SmallPill } from "components/Post/styles";
import { DashboardContainer } from "components/Admin/styles";
import Label from "components/Elements/Label";

export default function Completions() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getAllIBuiltThis().then(snapshot => {
      let data = Object.values(snapshot.val());
      data.map(submission => {
        fetchUserDataByUid(submission.uid).once("value", function(snapshot) {
          let data = snapshot.val();
          const object = { ...data, ...submission };
          setUserData(users => users.concat(object));
        });
      });
    });
  }, []);

  return (
    <DashboardContainer>
      <Label>
        {" "}
        <a href="/admin/dashboard"> &larr; Back</a>{" "}
      </Label>
      <h2>Project Completions</h2>

      {userData.length > 50 && (
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>postId</th>
              <th>title</th>
              <th>demo</th>
              <th>github</th>
              <th>description</th>
              <th>username</th>
              <th>email</th>
              <th>name</th>
              <th>time</th>
            </tr>
          </thead>
          <tbody>
            {userData
              .sort(function(a, b) {
                return new Date(b.date) - new Date(a.date);
              })
              .map((user, index) => {
                return (
                  <tr key={user && user.key && user.key}>
                    <td>{index}</td>
                    <td>{user && user.postId && user.postId}</td>
                    <td>{user && user.title && user.title}</td>
                    <td>
                      {" "}
                      <a href={user && user.demo && user.demo}>
                        {user && user.demo && user.demo ? (
                          <SmallPill c="#D1F0FD">Demo</SmallPill>
                        ) : (
                          "not submitted"
                        )}
                      </a>
                    </td>
                    <td>
                      {" "}
                      <a href={user && user.github && user.github}>
                        {user && user.github && user.github ? (
                          <SmallPill c="#D1F0FD">Source</SmallPill>
                        ) : (
                          "not submitted"
                        )}
                      </a>
                    </td>
                    <td>{user && user.description && user.description}</td>

                    <td>
                      {" "}
                      <a href={`/user/${user.id}`}>
                        @{user && user.id && user.id}
                      </a>
                    </td>
                    <td>{user && user.email_address && user.email_address}</td>
                    <td>{user && user.username && user.username}</td>

                    <td>{user && user.date && user.date}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </DashboardContainer>
  );
}
