import React, { Component } from "react";
import { navigate } from "gatsby";

import Completions from "components/Admin/Completions";

import withAuthorization from "../../components/Session/withAuthorization";
import { auth } from "api/firebase";

class CompletionsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (
      auth.currentUser.uid == "qODlzGtsA0QMv5hxNqC0qy6lIRy1" ||
      auth.currentUser.uid == "7bqTTkoDvua4M3lJDpLD2Ga9Lmp2" ||
      auth.currentUser.uid == "yN5k2h91h3WFNudUWfBT3suVVep2"
    ) {
      return <Completions />;
    } else {
      return "404"
    }
  }
}
const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(CompletionsPage);
